import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "../ImageQuerys/ProductImages";

const ProductSlides = (props) => {
  const { title, img, link } = props;

  return (
    <div className="product-slides">
      <Link to={link}>
        <Image imageName={img} alt="" />
      </Link>
      <h3>{title}</h3>
    </div>
  );
};

ProductSlides.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  link: PropTypes.string,
};
ProductSlides.defaultProps = {
  img: "",
  link: "",
  title: "",
};

export default ProductSlides;
