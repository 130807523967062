import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Carousel from "nuka-carousel";
// import SecondaryCta from '../Buttons/secondary-cta';
import ProductSlides from "./ProductSlides";
import ArrowLeft from "../CarouselCustomControls/ArrowLeft";
import ArrowRight from "../CarouselCustomControls/ArrowRight";
import useWindowSize from "../../hooks/use-window-size";
// import { solutionsSlides } from '../../data/carousel_data';
import ProductLinesStyles from "./ProductLinesStyles";

const { Title } = Typography;

const ProductLines = (props) => {
  const { sectionData, productSlides } = props;
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();

  useEffect(() => {
    const { width } = size;
    const isMobileOnly = width <= 576;
    const isTablet = width > 576 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200;

    const slides = () => {
      if (isMobileOnly) return 1.7;
      if (isTablet) return 2.5;
      if (isSreenLG) return 3.5;
      if (screenXL) return 4.5;
      return 2;
    };
    const spacing = () => {
      if (isMobileOnly) return 20;
      if (isTablet) return 30;
      return 50;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);

  return (
    <section className="product-lines">
      <Row className="product-lines__text">
        <Col span={24}>
          <Title level={2} className="new-line">
            {" "}
            {sectionData.title}
            <span className="blue_dot">.</span>{" "}
          </Title>
          <p style={{ marginBottom: 40 }}>{sectionData.subText}</p>
          {/* UNCOMMENT ONCE WE HAVE A PRODUCTS OVERVIEW PAGE */}
          {/* <p style={{ marginBottom: sectionData?.SecondaryCta ? 0 : 40 }}>
            {sectionData.subText}
          </p>
          {sectionData?.SecondaryCta && (
            <Row className="product-lines__cta">
              <SecondaryCta
                ctaTitle={sectionData.SecondaryCta.ctaTitle}
                target={sectionData.SecondaryCta.ctaTarget}
              />
            </Row>
          )} */}
        </Col>
      </Row>
      <div className="lateral-shadows">
        <Carousel
          slidesToShow={numberOfSlides}
          cellSpacing={cellSpacing}
          enableKeyboardControls
          // transitionMode="scroll3d"
          dragging
          // withoutControls
          wrapAround
          className="product-lines__carousel"
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowLeft onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowRight onClick={nextSlide} />
          )}
          renderBottomCenterControls={() => null}
          style={{ outline: "none" }}
        >
          {productSlides.map((slide, idx) => (
            <ProductSlides
              key={idx.toString()}
              img={slide.img}
              title={slide.title}
              link={slide.link}
            />
          ))}
        </Carousel>
      </div>

      <ProductLinesStyles />

      <style jsx="true">
        {`
          div.lateral-shadows section.testimonial-reviews {
            outline: none;
          }
        `}
      </style>
    </section>
  );
};

ProductLines.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  productSlides: PropTypes.arrayOf(PropTypes.any),
};
ProductLines.defaultProps = {
  sectionData: "",
  productSlides: [],
};

export default ProductLines;
