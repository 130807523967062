import React from "react";
import { viewports } from "../../style-vars";

const { mdOrSmaller } = viewports;

const ProductLinesStyles = () => (
  <style jsx global>{`
    .product-lines {
      position: relative;
      width: 100%;
      max-width: 1440px;
      margin-top: 60px;
      margin-bottom: 80px;
      box-sizing: border-box;
    }
    @media ${mdOrSmaller} {
      .product-lines {
        width: 100%;
        margin-bottom: 48px;
      }
    }
    @media ${mdOrSmaller} {
      .product-lines__carousel {
        left: 10px !important;
      }
    }
    .product-lines__carousel .slider-frame {
      overflow: visible !important;
    }
    .product-lines__text {
      max-width: 700px;
      margin: 0 auto;
    }
    @media ${mdOrSmaller} {
      .product-lines__text {
        padding: 0 16px;
      }
      .product-lines__text h2,
      .product-lines__text p {
        text-align: left !important;
      }
    }
    .product-lines__text h2,
    .product-lines__text p {
      text-align: center;
    }
    .product-lines__text p {
      color: #748093;
      font-size: 16px;
      line-height: 1.7;
    }
    .product-lines__cta {
      justify-content: center;
    }
    @media ${mdOrSmaller} {
      .product-lines__cta {
        justify-content: start;
      }
    }
    .product-lines .cta-secondary {
      margin: 42px auto 82px auto;
    }
    .product-slides {
      position: relative;
    }
    .product-slides h3 {
      text-align: center;
      margin-top: 20px;
      padding-bottom: 32px;
      font-weight: 800;
      font-size: 18px;
    }
    .product-slides img {
      display: table;
      margin: 0 auto;
      max-width: 100%;
      position: absolute;
      top: 5%;
    }
    .product-slides:before {
      content: "";
      width: 100%;
      height: 80%;
      background: #f3f4f6;
      position: absolute;
      bottom: 0px;
      left: 0;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
      z-index: -99;
    }
    .arrow {
      cursor: pointer;
    }
    .slider-control-centerleft {
      z-index: 2;
    }
    .slider-control-centerright {
      z-index: 2;
    }
  `}</style>
);

export default ProductLinesStyles;
